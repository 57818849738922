import React from 'react';
import YarnSourcingProduct from './YarnSourcingProduct';



const Customized = () => {
  return (
    <>
      
      <br />
      <br />
      <br />
      <YarnSourcingProduct />
      
      
    </>
  );
};

export default Customized;
