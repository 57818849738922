import React from 'react';
import ConsultancyProduct from './ConsultancyProduct';



const Consultancy = () => {
  return (
    <>
            <ConsultancyProduct />
      
      
    </>
  );
};

export default Consultancy;
