import React from 'react';
import GarmentSevicesProduct from './GarmentServicesProduct';



const Customized = () => {
  return (
    <>
      
      <br />
      <br />
      <br />
      <GarmentSevicesProduct />
      
      
    </>
  );
};

export default Customized;
