import React from 'react';
import ComplianceProduct from './ComplianceProduct';



const Compliance = () => {
  return (
    <>
      
      <ComplianceProduct />
      
      
    </>
  );
};

export default Compliance;
