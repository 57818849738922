import React from 'react';
import CertificationProduct from './CertificationProduct';


const Certification = () => {
  return (
    <>
            <CertificationProduct />
      
      
    </>
  );
};

export default Certification;
