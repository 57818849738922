import React from 'react';
import FabricSourcingProduct from './FabricSourcingProduct';



const Customized = () => {
  return (
    <>
      
      <br />
      <br />
      <br />
      <FabricSourcingProduct />
      
      
    </>
  );
};

export default Customized;
